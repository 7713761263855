import React from "react"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export default class Tab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0
        };
        this.switchTab = this.switchTab.bind(this);
    }

    switchTab(index) {
        this.setState({ activeTab: index });
    }

    render() {
        return (
            <>
                <div className="c-tabgroup">
                    <button onClick={() => this.switchTab(0)} className={`c-tab ${this.state.activeTab === 0 ? 'c-tab--active' : ''}`}>English</button>
                    <button onClick={() => this.switchTab(1)} className={`c-tab ${this.state.activeTab === 1 ? 'c-tab--active' : ''}`}>Français</button>
                </div>
                <div className={`o-content-container ${this.state.activeTab !== 0 ? 'u-hide' : ''}`}>
                    {documentToReactComponents(JSON.parse(this.props.essay.englishContent.raw))}
                </div>
                <div className={`o-content-container ${this.state.activeTab !== 1 ? 'u-hide' : ''}`}>
                    {documentToReactComponents(JSON.parse(this.props.essay.frenchContent.raw))}
                </div>
            </>
        );
    }
};
import React from "react"
import Seo from "../components/seo"
import "../styles/theme.scss"
import Layout from "../layouts/layout";
import { graphql } from "gatsby"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Tab from "../components/tabs"; 

const NotFound = ({ data, location }) => {
    let global = data.contentfulWebsite;
    let essay = data.contentfulEssayPage;
    return (
        <Layout pathname={location.pathname}>
            <Seo metaTitle={global.title} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
            <div className="o-wrapper o-content o-container o-content-container">
                <img className="u-border" alt={essay.title} src={essay.mainImage.resize.src} />
                <div className="u-text-center">
                    <h1>{essay.title}</h1>
                    <div className="u-text-small u-text-uppercase">
                        {documentToReactComponents(JSON.parse(essay.subtitle.raw))}
                    </div>
                </div>
                <Tab essay={essay} />
            </div>
        </Layout>
    );
};

export default NotFound

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "Black Ice"}) {
        author
        description
        title
        pageNotFoundMessage {
            raw
        }
        shareImage {
            file {
                url
            }
        }
    }
    contentfulEssayPage(id: {eq: "78f7fac7-2998-564d-a36c-24f8dd1e755b"}) {
        frenchContent {
            raw
        }
        englishContent {
            raw
        }
        title
        subtitle {
            raw
        }
        mainImage {
            resize(width: 1080) {
                src
            }
        }
    }
}
`;